import React from "react";

class Footer extends React.Component {
  render() {
    return (
<footer id="footer">
              <div className="insideFooter">
                <ul className="nav">
                <li>
                  <a id="ot-sdk-btn" className="ot-sdk-show-settings" rel="nofollow">Cookie Policy</a>
                </li>
                  
                  <li>
                    <a href="/es/legal/contactos/" target="_self">
                      Contacto
                    </a>
                  </li>
                  <li>
                    <a href="/es/legal/aviso-legal/" target="_self">
                      Aviso legal
                    </a>
                  </li>
                  <li>
                    <a href="/es/legal/politicia-de-privacidad/" target="_self">
                      Política de privacidad
                    </a>
                  </li>
                  <li>
                    <a href="/es/legal/politicia-de-cookies/" target="_self">
                      Política de cookies
                    </a>
                  </li>
                </ul>
                <div className="clear" />
                <p>
                  © JNTL Consumer Health (Spain), S.L. 2023. Este sitio web está
                  publicado por <br /> JNTL Consumer Health (Spain), S.L. único
                  responsable de su contenido.
                  <br />
                  Está dirigida a visitantes de España.
                  <br /> Ultima actualización: 01/02/2023
                </p>
                <p> </p>
              </div>
            </footer>
    );
  }
}

export default Footer;

import React from "react";
import Footer from "src/components/Footer_es";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        
        <div id="fb-root" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "(function(d, s, id) {\nvar js, fjs = d.getElementsByTagName(s)[0];\nif (d.getElementById(id)) return;\njs = d.createElement(s); js.id = id;\njs.src = \"//connect.facebook.net/es_ES/sdk.js#xfbml=1&version=v2.6&appId=607297119325946\";\nfjs.parentNode.insertBefore(js, fjs);\n}(document, 'script', 'facebook-jssdk'));\n"
          }}
        />
        <div id="disclosureText">
        </div>
        <div id="page" className="pageProducts">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/es/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="nav-path-selected first has-sub-nav">
                    <a className="es_header" href="/es/nuestros-productos/" target="_self">
                      Nuestros Productos
                    </a>
                    <ul>
                      <li>
                        <a href="/es/nuestros-productos/hydro-infusion/">
                          Hydro Infusion
                        </a>
                      </li>
                      {/* <li>
                        <a href="/es/nuestros-productos/active-protect/">
                          Active &amp; Protect
                        </a>
                      </li> */}
                      <li>
                        <a href="/es/nuestros-productos/moisturising/">
                          Moisturising
                        </a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/tan-protect/">
                          Tan &amp; Protect
                        </a>
                      </li>
                      {/* <li>
                        <a href="/es/nuestros-productos/instantglow/">
                          Instant Glow
                        </a>
                      </li> */}
                      <li>
                        <a href="/es/nuestros-productos/allergy/">Allergy</a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/mountain/">Mountain</a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/after-sun/">
                          After Sun
                        </a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/galeria/">Galeria</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="es_header" href="/es/nuestra-historia/" target="_self">
                      Nuestra historia
                    </a>
                  </li>
                  <li>
                    <a className="es__header" href="/es/conozca-el-sol/" target="_self">
                      Conozca el sol
                    </a>
                  </li>
                   {/*<li>
                    <a className="es_header" href="/es/donde-has-estado/" target="_self">
                      Dónde has estado 2019
                    </a>
                  </li>*/}
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      Países
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/our-products/mountain/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/our-products/mountain/">UK</a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/mountain/">ES</a>
                      </li>
                      
                      <li>
                        <a href="/it/prodotti/mountain/">IT</a>
                      </li>
                      
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="desktop-hero-wrapper" className="product-hero">
              <div id="bgImage">
                <div className="areaInner">
                  <img
                    border="0"
                    className="ccm-image-block"
                    alt
                    src="/assets/images/mountain-desktop-ES.jpg"
                    data-mobile="https://www.pizbuin.com/assets/images/mountain-mobile-ES.jpg"
                  />
                </div>
              </div>
            </section>
            <section id="content">
              <div className="products-page innerContent clearfix">
                <div className="products-page__intro">
                  <div className="container">
                    <h1>
                      PIZ BUIN<sup>®</sup> MOUNTAIN
                    </h1>
                    <div className="products-page__intro__description">
                      <h3>Protección a gran altura</h3>
                      <p>
                        ¿Sigues usando protección solar de verano en las
                        montañas? Las grandes alturas requieren una protección
                        especial. La gama PIZ BUIN® MOUNTAIN ha sido
                        especialmente diseñada para las condiciones a grandes
                        alturas, con una formulación innovadora que combina
                        filtros solares UVA/UVB avanzados y extracto de
                        Edelweiss que ayudan a proteger tu piel del sol y del
                        daño a largo plazo. Enriquecida con Cold Shield Complex,
                        provee de una hidratación de larga duración, protegiendo
                        la piel del frío extremo y el viento. PIZ BUIN® MOUNTAIN
                        ofrece una protección especial para el sol a gran altura
                        y condiciones de frío y viento.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="products-page__list container clearfix">
                  <div className="products-page__product clearfix">
                    <a name="crema-solare-stick-labbra" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/mountain-lipstick-new.png" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>PIZ BUIN® MOUNTAIN CREMA SOLAR + STICK LABIAL</h3>
                      <div className="products-page__product__details__benefits">
                        <h4>BENEFICIOS</h4>
                        <p>
                          Crema solar: Especialmente desarrollada para las
                          condiciones de gran altitud. Con filtros solares
                          UVA/UVB de avanzada tecnología y la antioxidante*
                          Edelweiss, ayuda a proteger la piel del sol y de los
                          daños a largo plazo. Enriquecida con el Complejo Cold
                          Shield, proporciona una hidratación de larga duración
                          que protege la piel del viento y del frío extremos.
                          Resistente al agua y a la transpiración.
                          <br />
                          *Test in vitro
                        </p>
                      </div>
                      <div className="products-page__product__details__factors">
                        <h4>SPF DISPONIBLE</h4>
                        <span className="factor factor50" />
                      </div>
                      <div className="products-page__product__details__directions">
                        <h4>APLICACIÓN</h4>
                        <p>
                          Aplicar de forma generosa y uniforme antes de la
                          exposición solar. Aplicar una cantidad insuficiente
                          disminuye el nivel de protección significativamente.
                          Re-aplicar con frecuencia, especialmente después de
                          transpirar, nadar o secarse con una toalla. Evitar el
                          sol de mediodía y permanecer mucho tiempo al sol,
                          incluso cuando se use protección solar. Mantener
                          alejados a los bebés y a los niños de la luz directa
                          del sol.
                        </p>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              INGREDIENTES Y TECNOLOGIAS{" "}
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                <div className="textBlock bottom">
                                  <p>
                                    Como expertos en cuidado solar, utilizamos
                                    tecnologías innovadoras para asegurar que
                                    consigues el balance perfecto entre
                                    bronceado y protección cuando disfrutas del
                                    sol.
                                  </p>
                                  <p>
                                    Cada uno de los avanzados filtros solares
                                    UVA/UVB es una poderosa tecnología de
                                    protección solar desarrollada por PIZ BUIN
                                    <sup>®</sup>. Ofrecen protección UVA y UVB
                                    fotoestable y de amplio espectro, y ayudan a
                                    conseguir que las cremas solares PIZ BUIN
                                    <sup>®</sup> cumplan con las últimas
                                    recomendaciones en productos de cuidado
                                    solar de la Comisión Europea.
                                  </p>
                                  <p>
                                    Todos los avanzados sistemas de filtros
                                    solares UVA/UVB ayudan a proteger de la
                                    radiación UVA y UVB. Rayos UVB – “rayos que
                                    queman” - dañan la superficie de la piel y
                                    son la principal causa de las quemaduras
                                    solares.{" "}
                                  </p>
                                  <p>
                                    Rayos UVA – “rayos que envejecen” – penetran
                                    más profundo y dañan el colágeno y la
                                    elastina de la piel, lo que puede resultar
                                    en su envejecimiento prematuro y cáncer.{" "}
                                  </p>
                                  <p>
                                    Los avanzados sistemas de filtros solares
                                    contienen tanto filtros UVA como UVB para
                                    ayudar a proteger tu pie del sol y el daño a
                                    largo plazo, a la vez que protegiéndola de
                                    los efectos más peligrosos de la radiación
                                    UVA. Cubren la mayoría del espectro UVA/UVB,
                                    ofreciendo una amplia protección a tu piel.
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d9ecfac1ba5e16cd4dcd7d6f49dc467c_f49.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                Glicerina
                                <div className="textBlock bottom">
                                  <p>
                                    La glicerina atrae el agua y lo mantiene en
                                    la piel para ayudar a que tenga un buen
                                    nivel de hidratación.
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/617fb948ebbaeb31693807e5a660cfbb_f43.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                COLD SHIELD COMPLEX
                                <div className="textBlock bottom">
                                  <p>
                                    El Cold Shield Complex ayuda a facilitar una
                                    hidratación duradera, protegiendo la piel
                                    del viento y frío extremos. Contiene manteca
                                    de karité, Trehalosa y glicerina.{" "}
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/4a08f11c5a84c8890abab5c8850f2fe6_f39.jpg" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                Manteca de karité
                                <div className="textBlock bottom">
                                  <p>
                                    La manteca de karité proviene del árbol de
                                    nuez de karité, originario de África
                                    occidental. Desde hace siglos se utiliza
                                    para hidratar y proteger la piel de los
                                    efectos del sol, el viento, el calor y el
                                    agua salada. También se usa mucho en
                                    cosmética por sus propiedades nutritivas,
                                    hidratantes y protectoras.{" "}
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/4e8fd1f288f22f5c0578667ed26ebe09_f46.jpg" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                TREHALOSE
                                <div className="textBlock bottom">
                                  <p>
                                    La Trehalosa es un descárido de origen
                                    natural que existe en una gran variedad de
                                    alimentos y animales. Se considera que
                                    protege a los organismos vivos ´de las
                                    condiciones ambientales como el frío
                                    extremo.{" "}
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/12e0f2e932194e93854b001526d89faa_f48.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                EDELWEISS
                                <div className="textBlock bottom">
                                  <p>
                                    Conocida como la “estrella blanca de los
                                    glaciares”, tiene un sistema natural de
                                    defensa que le permite sobrevivir al sol
                                    intenso y al frio y viento extremos común en
                                    los ambientes alpinos. El extracto de
                                    Edelweiss ayuda a neutralizar los radicales
                                    libres generados en la piel*, ayudando a
                                    mantener sus defensas contra la radiación
                                    UV.{" "}
                                  </p>
                                  <p>
                                    <span className="footNote">
                                      *Test In Vitro
                                    </span>
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/76186a208bd7eda9cefbe98fc7c193b8_f41.jpg" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <nav className="platform-links">
              <div className="container">
                <ul>
                  <li>
                    <a
                      className="first"
                      href="/es/nuestros-productos/hydro-infusion/"
                      target="_self"
                    >
                      Hydro Infusion
                    </a>
                  </li>
                  {/* <li>
                    <a
                      href="/es/nuestros-productos/active-protect/"
                      target="_self"
                    >
                      Active &amp; Protect
                    </a>
                  </li> */}
                  <li>
                    <a
                      href="/es/nuestros-productos/moisturising/"
                      target="_self"
                    >
                      Moisturising
                    </a>
                  </li>
                  <li>
                    <a
                      href="/es/nuestros-productos/tan-protect/"
                      target="_self"
                    >
                      Tan &amp; Protect
                    </a>
                  </li>
                  {/* <li>
                    <a
                      href="/es/nuestros-productos/instantglow/"
                      target="_self"
                    >
                      Instant Glow
                    </a>
                  </li> */}
                  <li>
                    <a href="/es/nuestros-productos/allergy/" target="_self">
                      Allergy
                    </a>
                  </li>
                  <li>
                    <a
                      className="platform-links__active nav-path-selected"
                      href="/es/nuestros-productos/mountain/"
                      target="_self"
                    >
                      Mountain
                    </a>
                  </li>
                  <li>
                    <a href="/es/nuestros-productos/after-sun/" target="_self">
                      After Sun
                    </a>
                  </li>
                  <li>
                    <a href="/es/nuestros-productos/galeria/" target="_self">
                      Galeria
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
            <section id="social">
              <a
                href="https://www.facebook.com/PizBuinEspana"
                target="_blank"
                className="fb"
              >
                &nbsp;
              </a>
              <a
                href="https://instagram.com/pizbuinespana"
                target="_blank"
                className="inst"
              >
                &nbsp;
              </a>
              <a
                href="https://www.youtube.com/channel/UC4UKe2bJ7_abRLvHmWKSzzg"
                target="_blank"
                className="yt"
              >
                &nbsp;
              </a>
            </section>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function(){\n$('.products-page__product__details__benefits__more').on('click', function(){\nvar box = $(this).siblings('.products-page__product__details__benefits__hidden');\nif(box.is(':visible')){\nbox.slideUp();\n$(this).text('Lee más');\n} else {\nbox.slideDown();\n$(this).text('Leer menos');\n}\n});\n});\n"
          }}
        />
        
        <div className="clear">
          <br />
        </div>
      </div>
    );
  }
}

export default Page;
